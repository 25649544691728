
$el-width: 200px
$el-height: 60px
$time: 12s
$circle-background: rgba(255, 255, 255, 0.1)
$circle-background-to: rgba(255, 255, 255, 0.1)

$background: black

body
  background: $background
  display: flex
  width: 100vw
  height: 100vh
  margin: 0
  padding: 0
  align-items: center
  justify-content: center
  //animation: rotating 40s linear infinite

.mandala-holder
  position: absolute
  left: 0
  otp: 0

#m-mand
  width: $el-width
  height: $el-width
  display: flex
  position: absolute
  //animation: rotating 20s linear infinite

.m-mand-item
  width: $el-width
  height: $el-height
  //background: rgba(255,255,255,1)
  //border: 1px solid rgba(255,255,255,0.2)
  position: absolute
  top: calc($el-width/2 - $el-height/2)
  left: 0
  animation: appearing $time linear infinite

.m-mand-item-circle
  width: $el-height
  height: $el-height
  border-radius: calc($el-height/2)
  border-width: 1px
  border-style: solid
  border-color: white
  position: absolute
  top: 0
  animation: scaling $time ease-in-out infinite

.m-mand-item-top
  left: 0
  //background: $circle-background
.m-mand-item-bot
  right: 0
  //background: $circle-background

@keyframes appearing
  0%
    opacity: 0.4
  50%
    opacity: 1
  100%
    opacity: 0.4

@keyframes scaling
  0%
    transform: scale(0.3)
    background: $circle-background
    //border-color: $circle-background
  50%
    transform: scale(1)
    background: $circle-background-to
    //border-color: $circle-background-to
  100%
    transform: scale(0.3)
    background: $circle-background
    //border-color: $circle-background

@keyframes rotating
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
